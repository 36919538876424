import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Placeholder from "./Placeholder";

import * as styles from "./PostPreview.module.css";

export default function PostPreview({ post }) {

    return (
        <div className={styles.wrapper}>
            <Link
                to={`/${post.slug}`}
            >
                {post.frontmatter.image && post.frontmatter.image !== null ?
                    <GatsbyImage
                        image={post.frontmatter.image.childImageSharp.gatsbyImageData}
                        alt={post.frontmatter.title}
                        className={styles.thumbnail}
                    />
                :
                    <Placeholder className={styles.thumbnail}/>
                }
            </Link>

            <div className={styles.copy}>
                <h3 className={styles.title}>
                    <Link to={`/${post.slug}`}>{post.frontmatter.title}</Link>
                </h3>
                {/* <small>{post.frontmatter.date}</small> */}
                <p className={styles.excerpt}>{post.frontmatter.description}</p>
            </div>
        </div>
    )
};