import * as React from "react"
import { graphql } from "gatsby";

import Layout from "../templates/Layout";
import PostPreview from "../components/preview/PostPreview";
import EventPreview from "../components/preview/EventPreview";

export const pageQuery = graphql`
    query HomeQuery {
        siteBuildMetadata {
            buildTime(formatString: "YYYY-MM-DD")
        }

        posts: allMdx(
            filter: {fileAbsolutePath: {regex: "data/update/"}}
            sort: {fields: frontmatter___date, order: DESC}
            limit: 4
        ){
            edges {
                node {
                    slug
                    frontmatter {
                        title
                        date
                        description
                        image {
                            childImageSharp {
                                gatsbyImageData(formats: JPG, width: 280)
                            }
                        }
                    }
                }
            }
        }

        events: allMdx(
            filter: {fileAbsolutePath: {regex: "data/calendar/"}}
            sort: {fields: frontmatter___start, order: ASC}
        ){
            edges {
                node {
                    slug
                    frontmatter {
                        title
                        start
                        end
                        compareTime: start(formatString: "YYYY-MM-DD")
                        isoYear: start(formatString: "YYYY")
                        isoStartDate: start(formatString: "MMMM Do")
                        isoStartTime: start(formatString: "h:mmA")
                        isoEndTime: end(formatString: "h:mmA")
                        locationName
                        locationCoords
                        isCancelled
                        image {
                            childImageSharp {
                                gatsbyImageData(formats: JPG, width: 280)
                            }
                        }
                    }
                }
            }
        }
    }
`


class RootIndex extends React.Component {
    render() {
        const posts = this.props.data.posts.edges;
        const events = this.props.data.events.edges;

        const buildDate = Date.parse(this.props.data.siteBuildMetadata.buildTime);

        let i = 0;

        const nextEvents = [ ...events];

        return (
            <Layout
                location={this.props.location}
                isHome
            >
                <h2>Recent news</h2>
                <ul className="list-normal">
                    {posts.map(({ node }) => {
                        return (
                            <li key={node.slug}>
                                <PostPreview post={node} />
                            </li>
                        )
                    })}
                </ul>

                {nextEvents.length > 0 &&
                    <div>
                        <h2>Upcoming events</h2>
                        <ul className="list-normal">
                        {nextEvents
                            .map(({ node }) => {
                                let postDate = Date.parse(node.frontmatter.compareTime);
                                
                                if(i < 3 && postDate > buildDate && !node.frontmatter.isCancelled) {
                                    i++;
                                    return (
                                        <li key={node.slug}>
                                            <EventPreview event={node} />
                                        </li>
                                    )
                                };
                            })
                        }
                        </ul>
                    </div>
                }
            </Layout>
        )
    }
}

export default RootIndex;


            